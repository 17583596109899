import React from "react"
import Webpage from "components/pages/web"
import Mobilepage from "components/pages/Mobile"
import Ecommercepage from "components/pages/Ecommerce"
import Gamepage from "components/pages/Game"
import UserExppage from "components/pages/UserExperience"
import Formpage from "components/pages/Form"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import { Helmet } from "react-helmet"
import "./styles.scss"
import DatabasePage from "components/pages/database"

const Servicespage = () => {
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>MercurySols Technologies | Services </title>
				<meta
					name="description"
					content="At Mercury Sols, we specialize in custom web development, mobile app development, eCommerce development, and game development services. As a leading IT solutions provider, our expertise in various tools and techniques allows us to prioritize customer satisfaction and measurable business outcomes. Our focus on delivering exceptional user experience sets us apart. Contact us to experience our commitment to providing top-quality web development services, website development, e-commerce solutions, and more."
				/>
			</Helmet>
			<HeaderTwo />
			<Webpage className="mt-5 pt-5" />
			<Mobilepage />
			{/* <DatabasePage /> */}
			<Ecommercepage />
			<Gamepage />
			<UserExppage />
			<Formpage />
			<Footer />
		</>
	)
}

export default Servicespage
