// extracted by mini-css-extract-plugin
export var Webparagraph = "styles-module--Webparagraph--kK7lT";
export var Webpic = "styles-module--Webpic--1cs0V";
export var cardsmainRow = "styles-module--cardsmainRow--0GJt4";
export var mainRow = "styles-module--mainRow--ky3Qp";
export var mainRow1 = "styles-module--mainRow1--cOok7";
export var mainWeb = "styles-module--mainWeb--0rmgU";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--oQX5S";
export var webHeading = "styles-module--webHeading--g9Ljg";
export var web_heading = "styles-module--web_heading--sm59e";
export var webblock = "styles-module--webblock--FpkZf";
export var webheading = "styles-module--webheading--c9rVR";
export var webser = "styles-module--webser--NPROm";
export var webtool = "styles-module--webtool--3cRUX";