import React from "react"
import * as styles from "./styles.module.scss"
import { Row, Col } from "react-bootstrap"
import UserExperienceSvg from "assets/icons/userExpsvg"
import AOS from "aos"
import "aos/dist/aos.css"
import { ServicesPage } from "../../../constants/index"

const UserExppage = () => {
	const height = 250
	const user = ServicesPage.user
	const userTechniques = ServicesPage.userTechniques
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<>
			<div className={styles.mainExprow} id="UsrExpDev">
				<Row className={styles.UserExp}>
					{user.map((user, index) => {
						return (
							<Col key={index}>
								<h2 className={styles.UserExp_heading}>{user.title}</h2>
							</Col>
						)
					})}
				</Row>
				<Row xs={1} md={2} className={styles.mainUserExp1}>
					<Col
						sm={12}
						md={4}
						className={styles.UserExppic}
						data-aos="flip-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="800"
						data-aos-mirror="true"
					>
						<UserExperienceSvg />
					</Col>
					{user.map((user, index) => {
						return (
							<Col key={index} className={styles.UserExpParagraph} sm={12} md={8}>
								<h2 className={styles.UserHeading}>{user.title}</h2>
								<p>{user.detail}</p>
							</Col>
						)
					})}
				</Row>
				<Row className={styles.mainUserExp1}>
					<div className={styles.techTools}>
						<p className={styles.UserExptool} md={4}>
							Tools and Technique
						</p>
					</div>
				</Row>
				<Row className={styles.mainUserExp} xs={1} sm={3} md={3} lg={4}>
					{userTechniques.map((userTechnique, index) => {
						return (
							<div key={index} className={styles.UserExpblock}>
								{userTechnique.icon}
								<p className={styles.UserExpHeading}>{userTechnique.title}</p>
							</div>
						)
					})}
				</Row>
			</div>
		</>
	)
}
export default UserExppage
