import React from "react"
import * as styles from "./styles.module.scss"
import { Row, Col } from "react-bootstrap"
import GameSvg from "assets/icons/gamesvg"
import AOS from "aos"
import "aos/dist/aos.css"
import { ServicesPage } from "../../../constants/index"

const Gamepage = () => {
	const height = 250
	const game = ServicesPage.game
	const gameTools = ServicesPage.gameTools

	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	return (
		<>
			<div className={styles.mainGamerow} id="GameDev">
				<Row className={styles.Gmae}>
					{game.map((game, index) => {
						return (
							<Col key={index}>
								<h2 className={styles.Game_heading}>{game.title}</h2>
							</Col>
						)
					})}
				</Row>
				<Row xs={1} md={2} className={styles.mainGame1}>
					<Col
						sm={12}
						md={4}
						className={styles.Gamepic}
						data-aos="flip-left"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="800"
						data-aos-mirror="true"
					>
						<GameSvg />
					</Col>
					{game.map((game, index) => {
						return (
							<Col key={index} className={styles.GameParagraph} sm={12} md={8}>
								<h2 className={styles.GameTitle}>{game.title}</h2>
								<p>{game.detil}</p>
							</Col>
						)
					})}
				</Row>
				<Row className={styles.mainGame1}>
					<div className={styles.techTools}>
						<p className={styles.Gametool} md={4}>
							Tools and Technique
						</p>
					</div>
				</Row>
				<Row className={styles.mainGmae} xs={1} sm={3} md={3} lg={4}>
					{gameTools.map((gameTool, index) => {
						return (
							<div key={index} className={styles.Gameblock}>
								{gameTool.icon}
								<p className={styles.GameHeading}>{gameTool.title}</p>
							</div>
						)
					})}
				</Row>
			</div>
		</>
	)
}
export default Gamepage
