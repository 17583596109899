import React from 'react';


export default function UserExperienceSvg(props) {
  return (
     <svg
      xmlns="http://www.w3.org/2000/svg"
      width="157"
      height="157"
      fill="none"
      viewBox="0 0 157 157"
    >
      <path
        fill="#fff"
        d="M77.903 105.064h-7.881a.991.991 0 01-1.005-1.004.992.992 0 011.005-1.005h7.881a.992.992 0 011.005 1.005.991.991 0 01-1.005 1.004zM41.165 55.578a.992.992 0 01-1.004-1.005v-7.159a.992.992 0 011.004-1.005.992.992 0 011.005 1.005v7.16a.992.992 0 01-1.005 1.004z"
      ></path>
      <path
        fill="#fff"
        d="M142.242 105.064H75.988a.991.991 0 01-1.005-1.004.992.992 0 011.005-1.005h66.254a4.986 4.986 0 004.993-4.993V31.997a4.986 4.986 0 00-4.993-4.993h-95.08a4.985 4.985 0 00-4.992 4.993v15.637a.992.992 0 01-1.005 1.005.992.992 0 01-1.004-1.005V31.997a7.009 7.009 0 017.002-7.003h95.079a7.009 7.009 0 017.002 7.003v66.065a7.008 7.008 0 01-7.002 7.002z"
      ></path>
      <path
        fill="#fff"
        d="M75.894 34.32h-.408v69.802h.408V34.32zM106.948 34.32h-.408v69.802h.408V34.32zM113.134 34.508h-.408v69.803h.408V34.508zM82.613 34.32h-.408v69.802h.408V34.32z"
      ></path>
      <path
        fill="#fff"
        d="M137.532 70.21h-24.9V45.28h24.9V70.21zm-23.927-1.004h22.891V46.315h-22.891v22.89z"
      ></path>
      <path
        fill="#fff"
        d="M113.699 69.928l-.439-.44 23.644-23.895.408.408-23.613 23.927z"
      ></path>
      <path
        fill="#fff"
        d="M136.621 69.708l-23.832-23.707.439-.408 23.833 23.675-.44.44zM107.043 70.21h-24.9V45.28h24.9V70.21zm-23.927-1.004h22.89V46.315h-22.89v22.89z"
      ></path>
      <path
        fill="#fff"
        d="M83.21 69.928l-.44-.44 23.645-23.895.408.408L83.21 69.928z"
      ></path>
      <path
        fill="#fff"
        d="M106.724 69.37L82.729 45.584l-.42.424 23.995 23.786.42-.424zM75.862 70.21H64.307v-1.004h10.55V46.315h-22.89v14.601h-1.005V45.279h24.9V70.21z"
      ></path>
      <path
        fill="#fff"
        d="M75.585 45.996l-.428-.416-14.993 15.378.427.416 14.994-15.378z"
      ></path>
      <path
        fill="#fff"
        d="M74.858 69.677L51.245 46l.44-.408 23.612 23.644-.44.44zM137.281 45.153H42.013v.408h95.268v-.408zM137.563 69.77H64.307v.409h73.256v-.408zM147.297 35.89H41.731a.992.992 0 01-1.005-1.005.992.992 0 011.005-1.004h105.566a.992.992 0 011.005 1.004.992.992 0 01-1.005 1.005z"
      ></path>
      <path
        fill="#fff"
        d="M137.626 34.32h-.408v69.802h.408V34.32zM36.141 142.682c-2.072 0-3.799-1.696-3.799-3.8a3.792 3.792 0 013.8-3.799c2.103 0 3.799 1.695 3.799 3.799s-1.696 3.8-3.8 3.8zm0-5.558c-.973 0-1.758.785-1.758 1.758 0 .974.785 1.759 1.758 1.759.974 0 1.759-.785 1.759-1.759 0-.942-.785-1.758-1.759-1.758zM64.558 129.054H8.761v2.01h55.797v-2.01z"
      ></path>
      <path
        fill="#fff"
        d="M59.032 146.638H13.847a6.098 6.098 0 01-6.091-6.092V66.254a6.097 6.097 0 016.091-6.092h45.185a6.097 6.097 0 016.092 6.092v74.292c.031 3.36-2.732 6.092-6.092 6.092zM13.847 62.172a4.074 4.074 0 00-4.082 4.082v74.292a4.074 4.074 0 004.082 4.082h45.185a4.074 4.074 0 004.082-4.082V66.254a4.074 4.074 0 00-4.082-4.082H13.847z"
      ></path>
      <path
        fill="#fff"
        d="M61.356 73.225H11.524v.408h49.832v-.408zM61.92 92.693H12.09v.408h49.832v-.408z"
      ></path>
      <path fill="#455963" d="M61.92 96.775H12.09v.408h49.832v-.408z"></path>
      <path
        fill="#fff"
        d="M14.256 68.64h-.409v51.999h.409V68.64zM33.63 68.64h-.409v51.999h.408V68.64zM39.062 68.64h-.409v51.999h.409V68.64zM58.373 68.672h-.409v51.998h.409V68.672z"
      ></path>
      <path
        fill="#fff"
        d="M48.513 116.682c-5.432 0-9.86-4.427-9.86-9.859s4.428-9.86 9.86-9.86c5.432 0 9.86 4.428 9.86 9.86 0 5.432-4.428 9.859-9.86 9.859zm0-19.31c-5.212 0-9.451 4.238-9.451 9.451 0 5.212 4.239 9.451 9.451 9.451 5.212 0 9.451-4.239 9.451-9.451 0-5.213-4.239-9.452-9.451-9.452zM23.707 116.682c-5.432 0-9.86-4.427-9.86-9.859s4.428-9.86 9.86-9.86c5.432 0 9.86 4.428 9.86 9.86 0 5.432-4.428 9.859-9.86 9.859zm0-19.31c-5.212 0-9.451 4.238-9.451 9.451 0 5.212 4.239 9.451 9.451 9.451 5.212 0 9.451-4.239 9.451-9.451 0-5.213-4.239-9.452-9.451-9.452zM48.513 93.07c-5.432 0-9.86-4.428-9.86-9.86 0-5.432 4.428-9.86 9.86-9.86 5.432 0 9.86 4.428 9.86 9.86 0 5.432-4.428 9.86-9.86 9.86zm0-19.311c-5.212 0-9.451 4.239-9.451 9.45 0 5.213 4.239 9.452 9.451 9.452 5.212 0 9.451-4.239 9.451-9.451 0-5.212-4.239-9.451-9.451-9.451zM23.707 93.07c-5.432 0-9.86-4.428-9.86-9.86 0-5.432 4.428-9.86 9.86-9.86 5.432 0 9.86 4.428 9.86 9.86 0 5.432-4.428 9.86-9.86 9.86zm0-19.311c-5.212 0-9.451 4.239-9.451 9.45 0 5.213 4.239 9.452 9.451 9.452 5.212 0 9.451-4.239 9.451-9.451 0-5.212-4.239-9.451-9.451-9.451zM58.31 32.342a1.742 1.742 0 01-1.727-1.727c0-.942.785-1.727 1.727-1.727.942 0 1.727.785 1.727 1.727 0 .973-.754 1.727-1.727 1.727zm0-2.857c-.628 0-1.13.502-1.13 1.13 0 .628.502 1.13 1.13 1.13.628 0 1.13-.502 1.13-1.13 0-.628-.502-1.13-1.13-1.13zM54.29 32.342a1.742 1.742 0 01-1.726-1.727c0-.942.785-1.727 1.727-1.727.942 0 1.727.785 1.727 1.727 0 .973-.785 1.727-1.727 1.727zm0-2.857c-.627 0-1.13.502-1.13 1.13 0 .628.503 1.13 1.13 1.13.629 0 1.131-.502 1.131-1.13-.031-.628-.534-1.13-1.13-1.13zM50.02 32.342a1.742 1.742 0 01-1.727-1.727c0-.942.785-1.727 1.727-1.727.942 0 1.727.785 1.727 1.727.032.973-.753 1.727-1.727 1.727zm0-2.857c-.628 0-1.13.502-1.13 1.13 0 .628.502 1.13 1.13 1.13.628 0 1.13-.502 1.13-1.13 0-.628-.502-1.13-1.13-1.13z"
      ></path>
    </svg>
  );
}
