// extracted by mini-css-extract-plugin
export var GameHeading = "styles-module--GameHeading--a8bVM";
export var GameParagraph = "styles-module--GameParagraph--dLKRa";
export var GameTitle = "styles-module--GameTitle--NMgbs";
export var Game_heading = "styles-module--Game_heading--uY6cI";
export var Gameblock = "styles-module--Gameblock--z0cJT";
export var Gamepic = "styles-module--Gamepic--12yWn";
export var Gamestatic = "styles-module--Gamestatic--lLiXS";
export var Gamestatics = "styles-module--Gamestatics--L-989";
export var Gametool = "styles-module--Gametool--yk+fc";
export var Gmae = "styles-module--Gmae--TUrB9";
export var mainGame1 = "styles-module--mainGame1--cU1py";
export var mainGamerow = "styles-module--mainGamerow--G4YhZ";
export var mainGmae = "styles-module--mainGmae--I2-Jp";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var techTools = "styles-module--techTools--R6R32";